import 'globals.css';
import { AppProps } from 'next/app';
import React, { ReactElement, ReactNode } from 'react';
import dynamic from 'next/dynamic';
import LogRocket from 'logrocket';

if (
  typeof window !== 'undefined' &&
  location.hostname !== 'localhost' &&
  !/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)
) {
  LogRocket.init('3ocevn/transcribekit');
}

const AuthProvider = dynamic(() =>
  import('lib/auth').then((m) => m.AuthProvider)
) as ({ children }: { children: ReactNode }) => ReactElement;

function MyApp({ Component, pageProps }: AppProps): ReactElement {
  const componentWithFlags = Component as {
    requiresAuth?: boolean;
  };

  if (componentWithFlags.requiresAuth) {
    return (
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
    );
  } else {
    return <Component {...pageProps} />;
  }
}

export default MyApp;
